/* app/ui/map/map.interactive */

import $ from 'jquery';
import { MediaQueries } from 'Util/mediaqueries';
import { NavSmall } from 'App/nav/small';
import { NavLarge } from 'App/nav/large';
import { subscribe } from 'Util/pubsub';

var selectors = {
    nav: '.js-navigation',
    navItem: '.js-navigation__item',
    navIcon: '.js-navigation__icon',
    navItemMega: '.js-navigation__item--megamenu',
    megamenuHeading: '.js-megamenu__heading',
    noTouch: '.no-touch'
};

var classes = {
    expanded: 'is-expanded',
    collapsed: 'is-collapsed',
    sticky: 'is-sticky'
};

var $nav;
var $search;
var $searchToggle;
var $langToggle;
var $lang;

var MapInteractive = {
    init: function () {
        NavLoad = this;
        NavLoad._initElements();
        NavLoad._initSubscriptions();
        NavLoad._initEvents();
        NavLoad._initMediaQueries();
    },

    _initElements: function () {
        $nav = $(selectors.nav);
        $searchToggle = $('.js-header__search-toggle');
        $search = $('.js-header__search');
        $langToggle = $('.js-header__lang-toggle');
        $lang = $('.js-header__lang');
    },

    _initSubscriptions: function () {
        subscribe('/nav/navToggle', NavLoad._closeSearch);
        subscribe('/nav/langToggle', NavLoad._closeSearch);
        subscribe('/nav/navToggle', NavLoad._closeLang);
        subscribe('/nav/searchToggle', NavLoad._closeLang);
    },

    _initEvents: function () {
        $searchToggle.on('click', NavLoad._toggleSearch);
        $langToggle.on('click', NavLoad._toggleLang);
    },

    // publish search toggle event
    // show/hide site search dependant on current state
    // toggle class on search button
    _toggleSearch: function (event) {
        event.preventDefault();
        publish('/nav/searchToggle');
        $search.toggleClass(classes.expanded);
        $searchToggle.toggleClass(classes.expanded);

        if ($search.hasClass(classes.expanded)) {
            $search.find('.js-header__search-input').focus();
        }
    },

    // close site search if open
    _closeSearch: function () {
        if ($searchToggle.hasClass(classes.expanded)) {
            $searchToggle.trigger('click');
        }
    },

    // publish language toggle event
    // show/hide site search dependant on current state
    // toggle class on language button
    _toggleLang: function (event) {
        event.preventDefault();
        publish('/nav/langToggle');
        $lang.toggleClass(classes.expanded);
        $langToggle.toggleClass(classes.expanded);
    },

    // close language picker if open
    _closeLang: function () {
        if ($langToggle.hasClass(classes.expanded)) {
            $langToggle.trigger('click');
        }
    },

    _initMediaQueries: function () {

        MediaQueries.register([
            {
                // Bind Small Nav
                queries: MediaQueries.queries['megamenu--small'],
                shouldDegrade: false,
                match: function () {
                    NavSmall.init($nav, selectors, classes);
                },
                unmatch: function () {
                    NavSmall.unbind($nav, selectors, classes);
                }
            }, {
                // Bind Large Nav
                queries: MediaQueries.queries['megamenu--large'],
                shouldDegrade: true,
                match: function () {
                    NavLarge.init($nav, selectors, classes);
                },
                unmatch: function () {
                    NavLarge.unbind($nav, selectors, classes);
                }
            }
        ]);
    }
};

export { MapInteractive }