/* app/ui/booking/booking-bar */

import $ from 'jquery';
import moment from 'moment';
import Pikaday from 'pikaday';
import { publish, subscribe, unsubscribe } from 'Util/pubsub';
import 'selectric';

var $bookingBar = $('.js-booking-bar');
var bookingBarHeight;
var bookingBarPos;
var topPos;
var $window;
var $body;
var $form;
var isCampaignLanding;


var checkinDate;
var checkoutDate;
var checkinPicker;
var checkoutPicker;

var BookingBar = {
    init: function () {
        BookingBar = this;
        $window = $(window);
        $body = $('body');
        $form = $('.js-booking-bar__form');
        isCampaignLanding = $('.js-campaign-page').length;

        $('.js-booking-select').selectric({
            disableOnMobile: false,
            nativeOnMobile: false,
            onInit: function () {
                $bookingBar.removeClass('u-hidden');
            }
        });
        BookingBar._setSizes();
        BookingBar._initDatePicker();
        BookingBar._bindEvent();

    },

    _bindEvent: function () {
        if (!isCampaignLanding) {
            subscribe('/window/scroll', BookingBar._stickyBookingBar);
        }
        $form.on('submit', BookingBar._validate);
    },

    _setSizes: function () {
        bookingBarHeight = $bookingBar.outerHeight();
        bookingBarPos = $bookingBar.offset().top;
    },

    _initDatePicker: function () {
        var now = new Date();

        checkinPicker = new Pikaday({
            field: $('.js-checkin-date')[0],
            format: 'DD/MM/YYYY',
            minDate: new Date(),
            maxDate: new Date(now.getFullYear() + 1, now.getMonth(), now.getDate()),
            onSelect: function () {
                checkinDate = this.getDate();
                BookingBar._updateStartDate();
            }
        });
        checkoutPicker = new Pikaday({
            field: $('.js-checkout-date')[0],
            format: 'DD/MM/YYYY',
            minDate: new Date(),
            maxDate: new Date(now.getFullYear() + 1, now.getMonth(), now.getDate()),
            onSelect: function () {
                checkoutDate = this.getDate();
                BookingBar._updateEndDate();
            }
        });
    },

    _updateStartDate: function () {
        checkinPicker.setStartRange(checkinDate);
        var nextDay = new Date(moment(checkinDate).add(1, 'days'));
        checkoutPicker.setStartRange(nextDay);
        checkoutPicker.setMinDate(nextDay);
        if (!checkoutDate) {
            checkoutPicker.setDate(nextDay);
        }
    },

    _updateEndDate: function () {
        checkinPicker.setEndRange(checkoutDate);
        checkinPicker.setMaxDate(checkoutDate);
        checkoutPicker.setEndRange(checkoutDate);
    },

    _stickyBookingBar: function () {
        var newTopPos = $window.scrollTop();
        var headerHeight = $('.js-header').outerHeight();

        if ($body.height() > ($(window).height() + 250)) {
            if (newTopPos + headerHeight > bookingBarPos || $window.width() < 1000) {//mobile always sticky
                publish('/bookingbar/sticky-start');
            } else {
                if (newTopPos === 0) {
                    $('.js-header').removeClass('is-sticky');
                }
                publish('/bookingbar/sticky-end');
            }
            topPos = newTopPos;
        }

        if (newTopPos > 0) {
            $bookingBar.addClass('is-sticky');
        } else {
            $bookingBar.removeClass('is-sticky');
        }

    },

    _validate: function (e) {
        var $form = $('.js-booking-bar__form');
        var valid = true;
        valid = BookingBar._validateRequired() && valid;
        $form.find('[data-val-required]').on('change', BookingBar._validateRequired);
        return valid;
    },

    _validateRequired: function () {
        var $form = $('.js-booking-bar__form');
        var valid = true;
        var $required = $form.find('[data-val-required]');
        for (var i = 0; i < $required.length; i++) {
            var $requiredInput = $required.eq(i);
            if ($requiredInput.val()) {
                valid = true;
                $('.js-booking-bar-error').empty();
            }
            else {
                $('.js-booking-bar-error').html($requiredInput.data('val-required'));
                return false;
            }
        }
        return valid;
    }
};

export { BookingBar };