
import $ from 'jquery';
import { Core } from 'Util/core';

var selectors = {
    list: '.js-expand-collapse__list',
    item: '.js-expand-collapse',
    trigger: '.js-expand-collapse__trigger',
    triggerAll: '.js-expand-collapse__all'
};

var dataSelectors = {
    overallState: 'expand-collapse-overall-state',
    openedText: 'expand-collapse-opened-text',
    closedText: 'expand-collapse-closed-text'
};

var classes = {
    triggerAllOpened: 'is-expanded'
};

var States = {
    OPENED: true,
    CLOSED: false
};

var ExpandCollapse = {
    init: function () {
        ExpandCollapse._initEvents();
        ExpandCollapse._initListOverallStates();
    },

    _initEvents: function () {
        $(document)
            .on('click keydown', selectors.trigger, Core.activate(ExpandCollapse._processTriggerClick))
            .on('click keydown', selectors.triggerAll, Core.activate(ExpandCollapse._processAllClick));
    },

    _initListOverallStates: function () {
        // For each list of expand/collapse items,
        // detect its initial overall state and store it

        var $lists = $(selectors.list);
        var $list;
        var i;

        for (i = 0; i < $lists.length; i++) {
            $list = $lists.eq(i);

            ExpandCollapse._recordListOverallState($list);
        }
    },

    _processTriggerClick: function (e) {
        // The expand/collapse trigger for a single item

        e.preventDefault();

        var $item = $(e.target).closest(selectors.item);

        ExpandCollapse._toggleItem($item);
    },

    _toggleItem: function ($item) {
        // Toggle an item's state between opened and closed

        var state;

        // Detect the current state so we can infer the action from that
        state = ExpandCollapse._getState($item);

        if (state === States.CLOSED) {
            // Currently closed, so open it
            ExpandCollapse._setState($item, States.OPENED);
        } else {
            // Currently opened, so close it
            ExpandCollapse._setState($item, States.CLOSED);
        }
    },

    _getState: function ($item) {
        if ($item.attr('aria-expanded') === 'false') {
            // Closed
            return States.CLOSED;
        } else {
            // Opened
            return States.OPENED;
        }
    },

    _setState: function ($item, state) {
        var $list = $item.closest(selectors.list);

        if (state === States.OPENED) {
            // Set to opened state
            $item.attr('aria-expanded', 'true');
            ExpandCollapse._processOpen($item);
        } else {
            // Set to closed state
            $item.attr('aria-expanded', 'false');
        }

        if ($list.length) {
            // If this item is part of a list,
            // update the list's overall state when the item's state changes
            ExpandCollapse._recordListOverallState($list);
        }
    },

    _processOpen: function ($item) {
        // Run when an item enters the opened state

        // When this first opens, make sure any lazyload images inside are told to load
        if ($item.data('lazyload-triggered')) {
            return;
        }
        $item.data('lazyload-triggered', true);

        $item.find('.js-lazy-auto').trigger('appear');
    },

    _recordListOverallState: function ($list) {
        // This is used for expand/collapse all buttons.
        // Overall state is States.OPENED if one or more
        // items are opened, otherwise it's States.CLOSED.

        var $items = $list.find(selectors.item);
        var $item;
        var $i;

        var $btn;

        var overallState = States.CLOSED;

        $items = $list.find(selectors.item);
        for (i = 0; i < $items.length; i++) {
            $item = $items.eq(i);
            if (ExpandCollapse._getState($item) === States.OPENED) {
                overallState = States.OPENED;
                break;
            }
        }

        // Record the overall state in the $list's data for easy retrieval
        $list.data(dataSelectors.overallState, overallState);

        // If there's an expand/collapse all button, update it
        $btn = $list.find(selectors.triggerAll);
        if ($btn.length) {
            ExpandCollapse._updateTriggerAllButton($btn, overallState);
        }

        return overallState;
    },

    _processAllClick: function (e) {
        // The expand/collapse all trigger for a list

        e.preventDefault();

        var $btn = $(e.target);
        var $list = $btn.closest(selectors.list);
        var $itemsToChange;
        var $item;
        var i;
        var overallState;
        var desiredState;

        if ($list.length === 0) {
            console.warn('ExpandCollapse: No list found when processing expand/collapse all event');
            return;
        }

        // Retrieve overall state and use it to infer desired state
        overallState = $list.data(dataSelectors.overallState);
        if (overallState === States.OPENED) {
            desiredState = States.CLOSED;
        } else {
            desiredState = States.OPENED;
        }

        // Create list of items that don't match the desired state
        $itemsToChange = $list
            .find(selectors.item)
            .filter(ExpandCollapse._filterItemsByState(overallState));

        // Change the state of each the items that don't match the desired state
        for (i = 0; i < $itemsToChange.length; i++) {
            $item = $itemsToChange.eq(i);
            ExpandCollapse._setState($item, desiredState);
        }

        // Update list overall state
        ExpandCollapse._recordListOverallState($list);
    },

    _filterItemsByState: function (state) {
        // Return a function to be used in $().filter,
        // to filter a list of items by a given state

        return function (i, el) {
            var $el = $(el);

            return ExpandCollapse._getState($el) === state;
        };
    },

    _updateTriggerAllButton: function ($btn, state) {
        // Update the text and class of a list's expand/collapse all button

        var newText = $btn.text();

        if (state === States.OPENED) {
            newText = $btn.data(dataSelectors.openedText) || newText;
            $btn.addClass(classes.triggerAllOpened);
        } else {
            newText = $btn.data(dataSelectors.closedText) || newText;
            $btn.removeClass(classes.triggerAllOpened);
        }

        $btn.text(newText);
    }
};

export { ExpandCollapse }