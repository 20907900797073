/* app/ui/back-to-top/back-to-top */

import $ from 'jquery';
import { MediaQueries } from 'Util/mediaqueries';
import { subscribe, unsubscribe } from 'Util/pubsub';

var $window;
var $backButton;

var BackToTop = {
    init: function () {
        BackToTop = this;
        $window = $(window);
        $backButton = $('.js-back-to-top');
        BackToTop._initEvent();
        BackToTop._initMediaQueries();
    },

    _initEvent: function () {
        $backButton.click(function (event) {
            event.preventDefault();
            $('body,html').animate({
                scrollTop: 0
            }, 800);
            return false;
        });
    },

    _initMediaQueries: function () {
        MediaQueries.register([
            {
                queries: MediaQueries.queries['below-768'],
                shouldDegrade: false,
                match: function () {
                    subscribe('/window/scroll', BackToTop._buttonStatucCheck);
                },
                unmatch: function () {
                    unsubscribe('/window/scroll', BackToTop._buttonStatucCheck);
                }
            },
            {
                queries: MediaQueries.queries['above-768'],
                shouldDegrade: true,
                match: function () {

                },
                unmatch: function () {

                }
            }
        ]);
    },

    _buttonStatucCheck: function () {
        var newTopPos = $window.scrollTop();

        if (newTopPos > 100) {
            $backButton.addClass('fade-in');
        }
        else {
            $backButton.removeClass('fade-in');
        }
    }
};

export { BackToTop };