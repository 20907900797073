/* app/ui/nav/small */

import $ from 'jquery';
import { publish, subscribe, unsubscribe } from 'Util/pubsub';

var $nav;
var classes;
var selectors;

var $navItems;
var $navLinks;
var $navToggle;
var elementsInit = false;

var NavSmall = {
    init: function ($elm, selectorList, classList) {
        NavSmall = this;
        $nav = $elm;
        selectors = selectorList;
        classes = classList;

        NavSmall._initElements();
        NavSmall._initSubscriptions();
        NavSmall._initEvents();
    },

    _initElements: function () {
        if (!elementsInit) {
            $navItems = $nav.find(selectors.navItem);
            $navLinks = $nav.find('.js-megamenu__heading');
            $navToggle = $('.js-header__nav-toggle');
            elementsInit = true;
        }
    },

    _initSubscriptions: function () {
        subscribe('/nav/searchToggle', NavSmall._closeNav);
        subscribe('/nav/langToggle', NavSmall._closeNav);
    },

    _initEvents: function () {
        $navToggle.on('click', NavSmall._toggleSmallMenu);
        $navLinks.on('click', NavSmall._openSubMenu);
    },

    unbind: function () {
        unsubscribe('/nav/searchToggle', NavSmall._closeNav);
        unsubscribe('/nav/langToggle', NavSmall._closeNav);
        $navToggle.off('click', NavSmall._toggleSmallMenu);
        $navLinks.off('click', NavSmall._openSubMenu);
        NavSmall._resetMenu();
    },

    // close primary nav menu if open
    _closeNav: function () {
        if ($navToggle.hasClass(classes.expanded)) {
            $navToggle.trigger('click');
        }
    },

    // publish nav toggle event
    // show/hide primary nav menu dependant on current state
    // toggle class on menu button
    _toggleSmallMenu: function (event) {
        event.preventDefault();
        publish('/nav/navToggle');
        $nav.toggleClass(classes.expanded + ' ' + classes.collapsed);
        $navToggle.toggleClass(classes.expanded);

        if ($nav.hasClass(classes.collapsed)) {
            $nav.find('.' + classes.expanded).removeClass(classes.expanded);
        }
    },

    _openSubMenu: function (event) {
        event.preventDefault();
        var $link = $(this);
        var $icon = $link.find(selectors.navIcon);
        var $item = $link.closest(selectors.navItem);

        if (!$item.hasClass(classes.expanded)) {
            $nav.find('.iconf-close').removeClass('iconf-close').addClass('iconf-down');
            $nav.find('.' + classes.expanded).removeClass(classes.expanded);
        }
        $item.toggleClass(classes.expanded);
        $icon.toggleClass('iconf-close iconf-down');
    },

    // collapse nav and any expanded children
    _resetMenu: function () {
        $nav.removeClass(classes.expanded);
        $navItems.removeClass(classes.expanded);
        $navToggle.removeClass(classes.expanded);
    }
};

export { NavSmall }