////////////////
// Every page //
////////////////

import $ from 'jquery';

import { LazyLoad } from 'Util/lazyload';
import { NavLoad } from 'App/nav/load';
import { TrackLoad } from 'App/track/load';
import { Rotator } from 'App/rotator/rotator';
import { ExpandCollapse } from 'App/expand-collapse/expand-collapse';
import { VideoPlayer } from 'App/video/video.player';
import { Social as SocialShare } from 'App/social/share';
import { MapLoad } from 'App/map/map.load';
import { UmbracoForms } from 'App/form/form.umbraco';
import { Tooltip } from 'App/tooltip/tooltip';
import { AjaxReplace } from 'App/ajaxreplace/ajaxreplace';
import { Cookie } from 'App/cookie/cookie';
import { BookingBar } from 'App/booking/booking-bar';
import { NavigationFeature } from 'App/rotator/navigation-feature';
import { BackToTop } from 'App/back-to-top/back-to-top';
import { CategoryFilter } from 'App/filter/category';

import { publish } from 'Util/pubsub';
import { throttle } from 'Util/throttle';

import 'Lib/parallax';
import 'Lib/terabyte/jquery.phatfingaz';
import 'Util/pubsub';
import 'Lib/jquery.matchHeight.min';
//import 'Lib/jquery.ba-throttle-debounce.min';

TrackLoad.init();
NavLoad.init();
LazyLoad.init();
Tooltip.init();
SocialShare.init();
Cookie.init();
CategoryFilter.init();

if ($('.js-ajax-replace').length) {
	AjaxReplace.init();
}

if ($('.js-booking-bar').length) {
	BookingBar.init();
}

if ($('.js-navigation-feature').length) {
	NavigationFeature.init();
}

// ExpandCollapse
ExpandCollapse.init();

// Video Macros
VideoPlayer.init();

// Umbraco Forms
UmbracoForms.init();

// Maps
var $maps = $('.js-map');
if ($maps.length) {
	MapLoad.init($maps);
}

// Rotators
var $rotators = $('.js-rotator');
if ($rotators.length) {
	Rotator.init($rotators);
}

//Back to top
if ($('.js-back-to-top').length) {
	BackToTop.init();
}

import AOS from 'Node/aos';
//Animate on scroll
AOS.init();

// Publish resize and scroll events for other modules to use
const $window = $(window);
$window.on('resize', throttle(() => publish('/window/resize'), 250));
$window.on('scroll', () => publish('/window/scroll'));
publish('/window/scroll');

$('.js-hot').phatFingaz();

// Parallax
var $parallax = $('.js-rotator--parallax');
var isFirefox = typeof InstallTrigger !== 'undefined';
var isChrome = !!window.chrome && !!window.chrome.webstore;

if ($parallax.length && (isFirefox || isChrome)) {
	$parallax.each(function () {
		var imageSrc = $(this).attr('data-image-src');
		$(this).parallax({
			imageSrc: imageSrc,
			bleed: 10
		});
	});
}
else {
	$parallax.each(function () {
		var imageSrc = $(this).attr('data-image-src');
		$(this).css('background-image', 'url(' + imageSrc + ')');
	});
}

//match height
var equaliseGroup = function () {
	var $group = $(this);
	$group.find('.js-equalise').matchHeight();
};

$('.js-equalise-group').each(equaliseGroup);

////////////////////////////////////  home page

import { FormValidate } from 'App/form/validate';
//import 'unobtrusiveAjax';

FormValidate.init();

////////////////////////////////////  details page

import { VideoLoad } from 'App/video/load';
import 'tablescroll';
import 'magnificPopup';

// Video Macros
VideoLoad.init();

// Check DOM for elements requiring JS
var $tables = $('.c-rte').find('table');

// Table Scroll
if ($tables.length) {
	$tables.tableScroll();
}

// Single and Multi Image Lightbox
if ($('.js-lightbox-single').length) {
	$('.js-lightbox-single').magnificPopup({
		delegate: '.js-lightbox-image',
		type: 'image'
	});
}

if ($('.js-lightbox-gallery').length) {
	$('.js-lightbox-gallery').each(function () {
		$(this).magnificPopup({
			delegate: '.js-lightbox-image',
			type: 'image',
			gallery: {
				enabled: true,
				navigateByImgClick: true,
				preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
			}
		});
	});
}
////////////////////////////////////  listing page

// ## TODO
// import { ListingMasonry } from 'App/listing/masonry';
// ListingMasonry.init();

import { InfiniteScroll } from 'App/infinitescroll/infinitescroll';
InfiniteScroll.init();

////////////////////////////////////  form page

import { } from 'App/form/validate';
//import 'unobtrusiveAjax';

FormValidate.init();




// Sanitize HTML

import sanitizeHtml from 'sanitize-html';

const body = document.querySelector('body');

console.log(sanitizeHtml(body));