/* app/ui/form/form.umbraco */

import $ from 'jquery';
import moment from 'moment';
import Pikaday from 'pikaday';

// Umbraco Forms JS is loaded separately in _Layout.cshtml

import { Track as UmbracoFormTracking } from 'App/track/umbraco-form';

import * as Core from 'Util/core';

var selectors = Object.freeze({
    umbracoForm: '.umbraco-forms-form form',
    errorMessage: '.field-validation-error',
    formField: '.umbraco-forms-field',
});

var UmbracoForms = {
    init: function () {
        UmbracoForms.initTracking();

        var $umbracoForms = $('.js-umbraco-form');

        if ($umbracoForms.length) {

            var $datepickers = $('.js-datepicker');
            if ($datepickers.length) {
                $datepickers.each(function () {
                    var datepicker = new Pikaday({
                        field: $(this)[0],
                        yearRange: 10,
                        format: 'YYYY-MM-DD'
                        //onSelect: function () {
                        //	var date = moment(datepicker.toString(), 'DD/MM/YYYY').format('DD/MM/YYYY');
                        //	$(this).attr('data-date', date);
                        //	$(this).val(moment(datepicker.toString(), 'DD/MM/YYYY').format('DD/MM/YYYY'));
                        //}
                    });
                });
            }
        }
    },

    initTracking: function () {
        if (document.querySelector('.js-track__umbraco-form')) {
            UmbracoFormTracking.init();
        }
    },
};

export { UmbracoForms };