
import $ from 'jquery'
import { Permissions } from 'App/permissions/permissions';
import 'Lib/jquery.cookie';

var shouldLoadOptIn = false;

var Cookie = {
    init: function () {
        // If there is 3rd party code that relies on cookies
        // but has not been loaded on the page, prompt the user
        // for permission to use cookies, then load the content if able

        Permissions.get({
            type: 'cookie',
            prompt: Cookie._prepareOptInCodeLoad,
            yes: Cookie._loadOptInCode,
            no: Cookie._clearAll
        });
    },

    set: function (name, value) {
        var setOnce = Cookie._setOnce(name, value);

        Permissions.get({
            type: 'cookie',
            prompt: setOnce,
            yes: setOnce,
            no: Cookie._clearAll
        });
    },

    _setOnce: function (name, value) {
        // Used as a callback for both prompt and yes,
        // but should only ever be called once

        var hasBeenCalled = false;
        return function () {
            if (hasBeenCalled) {
                return;
            } else {
                hasBeenCalled = true;
                $.cookie(name, value);
            }
        };
    },

    get: function (name) {
        var value = $.cookie(name);
        return value;
    },

    _prepareOptInCodeLoad: function () {
        // Used to ensure that opt-in code is only loaded
        // if the prompt was shown then the user selected "Yes"
        shouldLoadOptIn = true;
    },

    _loadOptInCode: function () {
        // The user has consented to cookies, so load
        // code that requires opt-in now that it can run

        if (!shouldLoadOptIn) {
            return;
        }

        // TODO: Once there is a URL to use, add it and uncomment this
        // var optInCodeUrl = '';

        // $.ajax(optInCodeUrl)
        // 	.done(Cookie._appendOptInCode)
        // 	.fail(Cookie._handleOptInCodeLoadFail);
    },

    _appendOptInCode: function (html) {
        // Callback when opt-in code has been retrieved
        console.info('Opt-in code successfully loaded', arguments);

        $('body').append(html);
    },

    _handleOptInCodeLoadFail: function () {
        console.error('Opt-in code failed to load', arguments);
    },

    _clearAll: function () {
        var cookies = $.cookie();
        for (var cookie in cookies) {
            $.removeCookie(cookie);
        }
    }
};

export { Cookie }