/* app/ui/nav/large */

import $ from 'jquery';
import { Core } from 'Util/core';
import { publish } from 'Util/pubsub';
import 'Lib/jquery.hoverIntent';

var $nav;
var selectors;
var classes;

var $window;
var $body;
var $anchorLinks;
var $navWithMenus;

var NavLarge = {
    init: function ($elm, selectorList, classList) {
        NavLarge = this;
        $nav = $elm;
        selectors = selectorList;
        classes = classList;

        NavLarge._initElements();

        NavLarge._initSubscriptions();
        NavLarge._initEvents();
    },

    _initElements: function () {
        $window = $(window);
        $body = $('body');
        $navWithMenus = $nav.find(selectors.navItemMega);
        $anchorLinks = $('.js-anchor-link');
    },

    // Listen for window resize event (listener bound on all.js)
    _initSubscriptions: function () {
        //subscribe('/window/resize', NavLarge._setSizes);

    },

    _initEvents: function () {

        // Using hover if "touch" device. Some caveats with this as it is really only WebKit devices. See https://github.com/Modernizr/Modernizr/issues/548 and https://github.com/Modernizr/Modernizr/issues/753
        import(/* webpackChunkName: "HoverIntent" */ 'Lib/jquery.hoverIntent').then(() => {

            if ($(selectors.noTouch).length) {
                $navWithMenus.hoverIntent({
                    over: NavLarge._toggleMegaMenuHover,
                    out: NavLarge._toggleMegaMenuHover,
                    interval: 30
                });
            }

        });

        $anchorLinks.on('click', NavLarge._processAnchorClick);

        if ($(selectors.noTouch).length) {
            return;
        }
        this._bindTouchEvents();
    },

    _toggleMegaMenu: function (target, click, event) {
        if (click === 'click') {
            event.preventDefault();
        }
        var $thisTarget = $(target);
        var $thisNav = $thisTarget.is(selectors.navItemMega) ? $thisTarget : $thisTarget.closest(selectors.navItemMega);
        var $expanded = $nav.find('.' + classes.expanded);

        if (click && click === 'click' && $expanded.find(selectors.megamenuHeading)[0] !== target) {
            $expanded.removeClass(classes.expanded);
        }

        $thisNav.toggleClass(classes.expanded);

    },

    unbind: function () {
        $anchorLinks.off('click', NavLarge._processAnchorClick);
        $navWithMenus.unbind('click', this._toggleMegaMenu);
        $navWithMenus.unbind('mouseenter').unbind('mouseleave');
        $navWithMenus.removeProp('hoverIntent_t');
        $navWithMenus.removeProp('hoverIntent_s');
    },

    _toggleMegaMenuHover: function () {
        if ($('.js-header').hasClass('is-sticky')) {
            publish('/nav/navToggle');
        }
        NavLarge._toggleMegaMenu(this);
    },

    _toggleMegaMenuClick: function (event) {
        if ($('.js-header').hasClass('is-sticky')) {
            publish('/nav/navToggle');
        }
        NavLarge._toggleMegaMenu(this, 'click', event);
    },

    _processAnchorClick: function (event) {
        event.preventDefault();

        var hash = this.href.split('#')[1];
        var $anchor = $('#' + hash);

        if ($anchor.length) {
            Core.scrollToElm($anchor, navHeight - 1);
        }
    },

    _bindTouchEvents: function () {
        $navWithMenus.each(function () {
            $(this).find(selectors.megamenuHeading).on('click', NavLarge._toggleMegaMenuClick);
        });
        $('body').on('click', NavLarge._handleBodyClick);
    },

    _handleBodyClick: function (e) {
        var $target = $(e.target);

        if (!$target.closest(selectors.nav).length && $nav.find('.' + classes.expanded).length) {
            $nav.find('.' + classes.expanded).removeClass(classes.expanded);
        }
    }
};

export { NavLarge }