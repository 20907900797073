import $ from 'jquery';

var selectors = {
	controlWrapper: '.js-category-filter-control-wrapper',
	categoryControl: '.js-category-filter-control-category',
	filetypeControl: '.js-category-filter-control-filetype',
	item: '.js-category-filter-item'
};

var dataSelectors = {
	target: 'category-filter-target',
	categories: 'category-filter-categories',
	filetype: 'category-filter-filetype'
};

var categoryDelimiter = ',';

var module = {
	init: function() {
		module._initEvents();
	},

	_initEvents: function () {
		$(selectors.controlWrapper)
			.on('change', selectors.categoryControl, module._applyFilter)
			.on('change', selectors.filetypeControl, module._applyFilter);
	},

	_applyFilter: function (e) {
		var $controlWrapper = $(e.target).closest(selectors.controlWrapper);
		var filter = module._createFilter($controlWrapper);

		var target = $controlWrapper.data(dataSelectors.target);
		var $target = $('#' + target);

		var $items = $target.find(selectors.item);

		var i;
		var $item;
		var itemMatch;

		var category;

		for (i = 0; i < $items.length; i++) {
			$item = $items.eq(i);
			itemMatch = module._applyFilterToItem(filter, $item);

			if (itemMatch) {
				$item.show();
			} else {
				$item.hide();
			}
		}
	},

	_createFilter: function($controlWrapper) {
		var $category = $controlWrapper.find(selectors.categoryControl);
		var $filetype = $controlWrapper.find(selectors.filetypeControl);

		var filetypeVal = $filetype.val();

		var filter = {
			category: $category.val()
		};

		if (filetypeVal) {
			filter.filetypes = filetypeVal.split(',');
		}

		return filter;
	},

	_applyFilterToItem: function(filter, $item) {
		var itemCategories = $item.data(dataSelectors.categories).split(categoryDelimiter);
		var itemFiletype = $item.data(dataSelectors.filetype);

		var itemMatch = true;

		if (filter.category) {
			itemMatch = itemMatch && itemCategories.indexOf(filter.category) !== -1;
		}

		if (filter.filetypes) {
			itemMatch = itemMatch && filter.filetypes.indexOf(itemFiletype) !== -1;
		}

		return itemMatch;
	}
};

export { module as CategoryFilter }