/* app/ui/rotator/rotator */

import $ from 'jquery';
import 'Lib/slick';
import { publish } from 'Util/pubsub';

var $rotators;
var isIE;
var isEdge;
var heroPrevArrowDesktop;
var heroNextArrowDesktop;

var Rotator = {
    init: function ($elms) {
        Rotator = this;
        $rotators = $elms;

        isIE = /*@cc_on!@*/false || !!document.documentMode;// Internet Explorer 6-11
        isEdge = !isIE && !!window.StyleMedia; // Edge 20+
        heroPrevArrowDesktop = '<button type="button" data-role="none" class="c-rotator--hero-arrow-prev-full" aria-label="Previous" tabindex="0" role="button"></button>';
        heroNextArrowDesktop = '<button type="button" data-role="none" class="c-rotator--hero-arrow-next-full" aria-label="Next" tabindex="0" role="button"></button>';
        if (isIE || isEdge) {
            heroPrevArrowDesktop = '<button type="button" data-role="none" class="slick-prev" aria-label="Previous" tabindex="0" role="button">Previous</button>';
            heroNextArrowDesktop = '<button type="button" data-role="none" class="slick-next" aria-label="Next" tabindex="0" role="button">Next</button>';
        }

        for (var i = 0; i < $rotators.length; i++) {
            var $thisRotator = $rotators.eq(i);
            var rotatorType = $thisRotator.attr('data-rotator-type') || 'content';

            if ($thisRotator.closest('.js-campaign-hero').length) {
                rotatorType = 'campaign';
            }

            var settings = Rotator._getRotatorSettings($thisRotator, rotatorType);

            $thisRotator.slick(settings);

            $thisRotator.on('init', Rotator._onInit);

            $thisRotator.on('beforeChange', Rotator._onBeforeChange);

            if (rotatorType === 'image' || rotatorType === 'offer') {
                $('.slick-arrow').on('click', Rotator._setAutoplayStop);
            }
        }
    },

    _onInit: function (slick) {
        var $visibleSlides = slick.$slides.filter(':visible');

        $visibleSlides.each(function () {
            var $slide = $(this);
            if ($slide.find('img[data-original]').length && !Rotator._isImageLoaded($slide)) {
                Rotator._processImg($slide);
            }
        });
    },

    // Runs before the next slide is shown
    // Check if image source is correct, if not - lazyload it
    _onBeforeChange: function (event, slick, currentSlide, nextSlide) {
        var $nextSlide = slick.$slides.eq(nextSlide);

        //if it's a lazy bg rotator
        if ($nextSlide.find('.js-lazy-bg').length && !Rotator._isBgLoaded($nextSlide)) {
            Rotator._processBg($nextSlide);
        }

        else if ($nextSlide.find('img[data-original]').length && !Rotator._isImageLoaded($nextSlide)) {
            Rotator._processImg($nextSlide);
        }
        //$(window).trigger('resize');
    },


    _onBeforeChangeMultiple: function (event, slick, currentSlide, nextSlide) {
        // todo: the number i defines the images to show.
        for (var i = 0; i < 3; i++) {
            var $nextSlide = slick.$slides.eq(nextSlide + i);
            if ($nextSlide.find('img[data-original]').length && !Rotator._isImageLoaded($nextSlide)) {
                Rotator._processImg($nextSlide);
            }
        }
    },

    // Process image lazyloading
    // Get the actual image source and overwrite the 'src' attribute
    _processImg: function ($nextSlide) {
        var $nextImg = $nextSlide.find('img');
        // in most case, rotator use different crop in different screensize, so copy all the crop imgs
        $.each($nextImg, function (index, item) {
            var nextImgSrc = $(item).data('original');
            $(item)[0].src = nextImgSrc;
        });
    },

    // Return true if the image 'src' attribute matches the 'data-original' attribute
    _isImageLoaded: function ($nextSlide) {
        var $nextImage = $nextSlide.find('img');
        return $nextImage[0].src.indexOf($nextImage.data('original')) !== -1;
    },

    _processBg: function ($nextSlide) {
        var $nextBg = $nextSlide.find('.js-lazy-bg');
        publish('/lazyload/bg', [$nextBg]);
    },

    _isBgLoaded: function ($nextSlide) {
        var $nextBg = $nextSlide.find('.js-lazy-bg');
        return $nextBg[0].style.backgroundImage.indexOf($nextBg.attr('data-original')) !== -1;
    },

    // Return the settings for the type of rotator that has been initialised
    // These can be edited, and new types can be added
    // Documentation for the options can be found here: http://kenwheeler.github.io/slick/
    _getRotatorSettings: function ($rotator, rotatorType) {
        var settings;

        switch (rotatorType) {
            case 'hero':
                settings = {
                    infinite: true,
                    //dots: true,
                    speed: 1600,
                    autoplaySpeed: 4000,
                    autoplay: $rotator.data('auto-rotate'),
                    prevArrow: heroPrevArrowDesktop,
                    nextArrow: heroNextArrowDesktop,
                    responsive: [
                        {
                            breakpoint: 1025,
                            settings: {
                                prevArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="Previous" tabindex="0" role="button">Previous</button>',
                                nextArrow: '<button type="button" data-role="none" class="slick-next" aria-label="Next" tabindex="0" role="button">Next</button>'
                            }
                        }

                    ]
                };
                break;
            case 'image':
                settings = {
                    infinite: true,
                    autoplay: false
                };
                break;

            case 'offer':
                settings = {
                    infinite: true,
                    speed: 1600,
                    autoplaySpeed: 4000,
                    autoplay: $rotator.data('auto-rotate')
                };
                break;

            case 'carousel':
                settings = {
                    infinite: false,
                    slidesToShow: 3,
                    responsive: [
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 479,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]
                };
                break;

            case 'dual':
                var navId = $rotator.attr('data-carousel-dual');
                settings = {
                    infinite: false,
                    slidesToShow: 1,
                    fade: true,
                    asNavFor: '[data-carousel-dual-nav="' + navId + '"]'
                };
                break;

            case 'dual-nav':
                var mainId = $rotator.attr('data-carousel-dual-nav');
                settings = {
                    infinite: false,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    asNavFor: '[data-carousel-dual="' + mainId + '"]',
                    focusOnSelect: true,
                    responsive: [
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 479,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                };
                break;

            case 'campaign':
                settings = {
                    prevArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="Previous" tabindex="0" role="button">Previous</button>',
                    nextArrow: '<button type="button" data-role="none" class="slick-next" aria-label="Next" tabindex="0" role="button">Next</button>',
                    autoplay: true,
                    autoplaySpeed: 4000,
                    cssEase: 'linear',
                    fade: true,
                    speed: 800
                }
                break;

            default:
                settings = {

                };
        }
        return settings;
    },

    _setAutoplayStop: function () {
        $(this).closest('.slick-slider').slick('slickPause');
        $(this).closest('.slick-slider').autoPlay = $.noop;
    }
};

export { Rotator }