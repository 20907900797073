/* app/ui/ajaxreplace/ajaxreplace */

import $ from 'jquery';
import { Core } from 'Util/core';
import { publish } from 'Util/pubsub';

var selectors = {
    // Classes
    container: '.js-ajax-replace',
    link: '.js-ajax-replace-link',
    select: '.js-mobile-select',
    scrollTop: '#active-top',
    loadingContainer: '.js-loading-container',
    loadingClass: 'is-loading',
    // Data
    ajaxUrl: 'ajax'
};

var historySupport = window.history && window.history.pushState;

var AjaxReplace = {
    init: function () {
        AjaxReplace = this;
        AjaxReplace._initEvents();
    },

    _initEvents: function () {
        $(document).on('click', selectors.link, AjaxReplace._processLinkClick);
        $(document).on('change', selectors.select, AjaxReplace._filterSelectChange);
        window.onpopstate = AjaxReplace._processPopState;
    },

    _processLinkClick: function (e) {
        e.preventDefault();
        var $link = $(this);
        var $container = $link.closest(selectors.container);
        var url = $container.data(selectors.ajaxUrl);
        var query = this.href;
        $(selectors.loadingContainer).addClass('is-loading');
        query = query.match(/(\?[^#]*)($|#)/);
        if (!query) {
            // No query string on ajax link, so treat as a normal link
            return;
        }
        e.preventDefault();

        query = query[1];
        url = url + query;

        $.ajax({
            url: url,
            success: AjaxReplace._processAjaxSuccess($container, query),
            error: AjaxReplace._processAjaxError
        });
    },

    _processAjaxSuccess: function ($container, url) {
        return function (html, status, request) {
            var oldHistoryState;
            var newHistoryState;
            var ajaxUrl;
            var $newHtml = $(html).children();
            $container.replaceWith($newHtml);
            publish('/lazyload/image', [$(selectors.container).find('.js-lazy-auto')]);

            Core.scrollToElm($(selectors.scrollTop), 80);

            setTimeout(function () {
                var equaliseGroup = function () {
                    var $group = $(this);
                    $group.find('.js-equalise').matchHeight();
                };
                $('.js-equalise-group').each(equaliseGroup);
            }, 500);

            setTimeout(function () {
                $(selectors.loadingContainer).removeClass('is-loading');
            }, 1000);

            if (historySupport) {
                // History state objects have to be able to be serialised,
                // so the HTML object can't be stored directly. Instead, store
                // the ajax URL and use that to find the same container again
                // This method is build on the assumption that there is only
                // one ajax container with this ajax URL on the page
                ajaxUrl = $container.data(selectors.ajaxUrl);

                oldHistoryState = {
                    ajaxUrl: ajaxUrl,
                    html: $container.html()
                };

                newHistoryState = {
                    ajaxUrl: ajaxUrl,
                    html: html
                };

                history.replaceState(oldHistoryState, document.title, document.location.href);
                history.pushState(newHistoryState, document.title, url);
            }
        };
    },

    _processAjaxError: function (response, status, message) {
        console.error('AJAX load failed:', message);
    },

    _processPopState: function (event) {
        var state = event.state;
        var $container;

        if (state) {
            $container = $(selectors.container + '[data-' + selectors.ajaxUrl + '="' + state.ajaxUrl + '"]');
            $container.html(state.html);
        }
    },

    _filterSelectChange: function () {
        var $select = $(this);
        var selectedValue = $select.val();

        $select.closest('.js-mobile-select-wrapper').find(selectors.link).filter('[data-value="' + selectedValue + '"]').trigger('click');
    },
};

export { AjaxReplace };