/* app/ui/infinitescroll/infinitescroll */

import $ from 'jquery';
import { Button } from 'App/infinitescroll/button';
import { Pagination } from 'App/infinitescroll/pagination';
import { Content } from 'App/infinitescroll/content';

var InfiniteScroll = {
    init: function () {
        Pagination.init();
        Content.init();
        Button.init();
    }
};

export { InfiniteScroll }