/* app/ui/video/video.player */

import $ from 'jquery';

var VideoPlayer = {
    init: function () {
        VideoPlayer = this;

        VideoPlayer._initEvents();
    },

    _initEvents: function () {
        $(document).on('click', '.js-video-player .js-video-cover', VideoPlayer._loadVideo);
    },

    // Retrieve embed URL from link
    // Create iframe embed element
    // Insert iframe into player element
    // Add class to cover to control its appearance
    _loadVideo: function (event) {
        event.preventDefault();
        var $cover = $(this);
        var embedSrc = $cover.attr('href');
        var $embedElm = $('<iframe src="' + embedSrc + '?autoplay=1&rel=0" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
        var $player = $cover.closest('.js-video-player');

        $player.prepend($embedElm);
        $cover.addClass('is-playing');
    }
};

export { VideoPlayer }