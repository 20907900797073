/* app/ui/nav/load */

import $ from 'jquery';
import { MediaQueries } from 'Util/mediaqueries';
import { Keybinding } from 'Util/keybinding';
import { NavSmall } from 'App/nav/small';
import { NavLarge } from 'App/nav/large';
import { publish, subscribe, unsubscribe } from 'Util/pubsub';

var selectors = {
    nav: '.js-navigation',
    header: '.js-header',
    navItem: '.js-navigation__item',
    navIcon: '.js-navigation__icon',
    navItemMega: '.js-navigation__item--megamenu',
    megamenuHeading: '.js-megamenu__heading',
    noTouch: '.no-touch'
};

var classes = {
    expanded: 'is-expanded',
    collapsed: 'is-collapsed',
    sticky: 'is-sticky'
};

var $nav;
var $search;
var $searchToggle;
var $langToggle;
var $lang;

var $window;
var $body;
var $header;
var headerHeight;
var headerPos;
var topPos;

var NavLoad = {
	init: function () {
		NavLoad = this;
		NavLoad._initElements();

		if ($nav.length) {
			NavLoad._initSubscriptions();
			NavLoad._setSizes();
			NavLoad._initEvents();
			NavLoad._initMediaQueries();
		}
	},

	_initElements: function () {
		$nav = $(selectors.nav);
		$searchToggle = $('.js-header__search-toggle');
		$search = $('.js-header__search');
		$langToggle = $('.js-header__lang-toggle');
		$lang = $('.js-header__lang');

		$header = $(selectors.header);
		$window = $(window);
		$body = $('body');
	},

	_initSubscriptions: function () {
		subscribe('/nav/navToggle', NavLoad._closeSearch);
		subscribe('/nav/langToggle', NavLoad._closeSearch);
		subscribe('/nav/navToggle', NavLoad._closeLang);
		subscribe('/nav/searchToggle', NavLoad._closeLang);

		subscribe('/window/scroll', NavLoad._stickyHeader);
		NavLoad._bindSubscribeStickyHeader();

		subscribe('/bookingbar/sticky-start', NavLoad._unbindSubscribeStickyHeader);
		subscribe('/bookingbar/sticky-end', NavLoad._bindSubscribeStickyHeader);
	},

	_initEvents: function () {
		$searchToggle.on('click', NavLoad._toggleSearch);
		$langToggle.on('click', NavLoad._toggleLang);

		Keybinding.bindKey('?', NavLoad._focusSearch);
	},

	// publish search toggle event
	// show/hide site search dependant on current state
	// toggle class on search button
	_toggleSearch: function (event) {
		event.preventDefault();
		publish('/nav/searchToggle');
		//setTimeout(function () {
			$search.toggleClass(classes.expanded);
		//}, 300);
		$searchToggle.filter(':visible').toggleClass(classes.expanded);
		$searchToggle.find('.c-header__search-icon').addClass('iconf-search').removeClass('iconf-close');

		if ($search.hasClass(classes.expanded)) {
			//setTimeout(function () {
				$search.find('.js-header__search-input').focus();
			//}, 300);
			$searchToggle.find('.c-header__search-icon').removeClass('iconf-search').addClass('iconf-close');

		}
	},

	// focus on the search element
	_focusSearch: function (event) {
		$search.find('.js-header__search-input').focus();
	},

	// close site search if open
	_closeSearch: function () {
		if ($searchToggle.filter(':visible').hasClass(classes.expanded)) {
			$searchToggle.filter(':visible').trigger('click');
		}
	},

	// publish language toggle event
	// show/hide site search dependant on current state
	// toggle class on language button
	_toggleLang: function (event) {
		event.preventDefault();
		publish('/nav/langToggle');
		$lang.toggleClass(classes.expanded);
		$langToggle.toggleClass(classes.expanded);
	},

	// close language picker if open
	_closeLang: function () {
		if ($langToggle.hasClass(classes.expanded)) {
			$langToggle.trigger('click');
		}
	},

	_initMediaQueries: function () {

		MediaQueries.register([
			{
				// Bind Small Nav
				queries: MediaQueries.queries['megamenu--small'],
				shouldDegrade: false,
				match: function () {
					NavSmall.init($nav, selectors, classes);
				},
				unmatch: function () {
					NavSmall.unbind($nav, selectors, classes);
				}
			}, {
				// Bind Large Nav
				queries: MediaQueries.queries['megamenu--large'],
				shouldDegrade: true,
				match: function () {
					NavLarge.init($nav, selectors, classes);
				},
				unmatch: function () {
					NavLarge.unbind($nav, selectors, classes);
				}
			}
		]);
	},

	_setSizes: function () {
		headerHeight = $header.outerHeight();
		headerPos = $header.offset().top;
	},

	_stickyHeader: function () {
		var newTopPos = $window.scrollTop();

		if (newTopPos > 0) {
			$header.addClass(classes.sticky);
			$body.css('padding-top', '0px !important');
		} else {
			$header.removeClass(classes.sticky);
			$body.css('padding-top', '0px !important');
		}
		topPos = newTopPos;
	},

	_bindSubscribeStickyHeader: function () {
		NavLoad._stickyHeader;
	},

	_unbindSubscribeStickyHeader: function () {
		NavLoad._stickyHeader;
	}
};

export { NavLoad }