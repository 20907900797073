/* app/ui/rotator/rotator */

import $ from 'jquery';

var $navigationItem = $('.js-navigation-feature__item');
var $featureImage = $('.js-feature-image');

var delay = 5000;

var NavigationFeature = {
    init: function () {
        NavigationFeature = this;
        NavigationFeature._autoRotate();
    },

    _autoRotate: function () {
        var index = 1;
        var auto = setInterval(function () {
            $navigationItem.removeClass('is-current');
            $navigationItem.eq(index).addClass('is-current');

            $featureImage.fadeTo(1200, 0.1, function () {
                $featureImage.attr("src", $navigationItem.eq(index).data('image-url'));
                if (index === $navigationItem.length - 1) {
                    index = 0;
                }
                else {
                    index = index + 1;
                }
            }).fadeTo(1200, 1);
        }, delay);
    }
};

export { NavigationFeature }